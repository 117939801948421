<template>
    <div>
        <SubHeader :title="'DEPOSIT'" :link="'/mypage'"/>
        <div class="content_wrap">
            <div class="rounded-1 bg-white text-center">
                <div class="py-px-12 mx-3 border-bottom">
                    <span class="fw-bold">{{coin}}</span> DEPOSIT
                </div>
                <div class="py-px-12">
                    <small @click="info_open = !info_open">Personal Information</small>
                    <i class="fas fa-chevron-down text-yellow-1 small ms-2"></i>

                    <div class="small pt-2" v-show="info_open">
                        <p>MY EMAIL : <span class="text-decoration-underline">{{email}}</span></p>
                        <p>UID : {{uid}}</p>
                    </div>
                </div>
            </div>

            <div class="text-white text-center py-4">
                <p class="fs-px-12 lh-sm">
                    The following address only supports {{coin}} transaction.<br>
                    Do not send any token other than {{coin}}.<br>
                    If you deposit other currency your asset will be lost.
                </p>
            </div>

            <div class="text-center py-3">
                <!-- {{ address }} -->
                <!-- <img src="https://via.placeholder.com/160x160" alt="qrcode"> -->
                <!-- <qrcode-vue :value="address" :size="160" level="H" v-if="address !=''"/> -->
                <div class="mx-auto" style="width: 160px;" v-if="address !=''"><qr-code :text="address" :size="160" id="qrcode"></qr-code></div>
                
                <!-- <vue-qrcode :value="address" v-if="address !=''" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/> -->
            </div>

            <div class="rounded-1 bg-white py-px-12 text-center">
                <span>{{address}}</span>
            </div>

            <div class="flex-between-center mt-2">
                
                <button class="h-px-48 border-0 rounded-1 bg-yellow-1 btn w-100 flex-center-center me-2" @click="SaveQrCode()">
                    <small class="fs-px-12 lh-1">Save<br>QR Code</small>
                </button>
                <button class="h-px-48 border-0 rounded-1 bg-burgundy-1 btn w-100 flex-center-center text-white" @click="CopyAddress(address)">
                    <small class="fs-px-12 lh-1">Copy<br>Address</small>
                </button>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import SubHeader from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
const CryptoJS = require("crypto-js");




export default {
    components:{
        SubHeader, Footer
    },

    data(){
        return{
            email: '',
            uid: '',
            address: '',
            coin: this.$route.params.symbol,

            // front ui
            info_open: false,
        }
    },
    mounted(){
        this.GetWalletDepositInfo()
    },
    methods:{
        GetWalletDepositInfo(){
            const coin = this.coin;
            const body = {coin};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post('/member/transaction/GetWalletDepositInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.email = body.info.email;
                            this.uid = body.info.uid;
                            this.address = body.info.address;
                        }else if(res.data.code =="9999"){
                            this.$store.dispatch('logout').then(
                                ()=>{
                                location.href = `/signin`;
                                }
                            );
                        }
                    }
                }   
            )
        },
        CopyAddress(address){
            this.$pp({
                msg:'Copied!',
                auto_hide:true
            })
            this.$copy(address)
        },
        SaveQrCode(){
            this.$pp({
                msg:'Complete!',
                auto_hide:true
            })
            var curr_Element = document.getElementById("qrcode");
            var child_Elements = curr_Element.childNodes[1].src;
            const dataURL = child_Elements;
            this.Download(dataURL,'QR_CODE');
        },
        Download(href, name) {
            let eleLink = document.createElement('a')
            eleLink.download = name
            eleLink.href = href
            eleLink.click()
            eleLink.remove()
        },
    }

}
</script>

<style>

</style>